import Proj000 from "../assets/proj000.png"
import Proj001 from "../assets/proj001.png"
import Proj002 from "../assets/proj002.png"
import Proj003 from "../assets/proj003.png"
import Proj1 from "../assets/proj1.png"
import Proj2 from "../assets/proj2.png"
import Proj3 from "../assets/proj3.png"
import Proj4 from "../assets/proj4.png"
import Proj5 from "../assets/proj5.png"
import Proj6 from "../assets/proj6.png"

export const ProjectList = [

    {
        name: "CaviLux Limo",
        desc: "Published website for Luxury Executive Car Services created for my clients business",
        image: Proj000,
        skills: "Web Design, Client Communication/Satisfaction, Adaptive/Responsive Layouts, UI/UX, Adaptability ",
        link: "Link to Project:",
        GithubProject: "https://caviluxlimo.com"
    },
    {
        name: "Help Me Grow Montessori",
        desc: "Published website for my clients Child Daycare business",
        image: Proj003,
        skills: "Web Design, Client Communication/Satisfaction, Simplistic Designs, UI/UX, Adaptability ",
        link: "Link to Project:",
        GithubProject: "https://helpmegrowmontessori.com"
    },
    {
        name: "TLC Homelike Childcare",
        desc: "Published website for my clients Child Daycare business",
        image: Proj001,
        skills: "Web Design, Client Communication/Satisfaction, Simplistic Designs, UI/UX, Adaptability ",
        link: "Link to Project:",
        GithubProject: "https://tlchomelikechildcare.me"
    },
    {
        name: "Kiddos Castle",
        desc: "Published website for my clients Child Daycare business",
        image: Proj002,
        skills: "Web Design, Client Communication/Satisfaction, Simplistic Designs, UI/UX, Adaptability ",
        link: "Link to Project:",
        GithubProject: "https://kiddoscasle.com"
    },
    {
    name: "Tic Tac Toe",
    desc: "Play with a partner on the same device or a really smart AI",
    image: Proj1,
    skills: "HTML, CSS, JavaScript, MinMax Algorithm (AI)",
    link: "Link to Project:",
    GithubProject: "https://github.com/AinazEstiri/TicTacToe-with-AI"
},
{
    name: "8 Puzzle Graph Solver",
    desc: "Utilizes 3 different Search Algorithms to optimize finding the solution to 8 Puzzle boards",
    image: Proj2,
    skills: "Python, Optimization Algorithms: Uniform Cost Search, Euclidean Distance, and Misplaced Tile",
    link: "Link to Project:",
    GithubProject: "https://github.com/AinazEstiri/8-Puzzle-Graph-Search-/tree/main"
},

{
    name: "Nearest Neighbor",
    desc: "Finding the nearest neighbor for datasets of different sizes",
    image: Proj3,
    skills: "Python, Matplotlib, Greedy Algorithms: Forward Selection Backward Elimination Search",
    link: "Link to Project:",
    GithubProject: "https://github.com/AinazEstiri/Nearest-Neighbor/tree/main"
},
{
    name: "Task Scheduler",
    desc: "A useful productivity tool, in the form of a comprehensive todo list, with a visual representation of tasks users want to complete",
    image: Proj4,
    skills: "C++, Google Tests, Valgrind, CMake/Makefile, Sort Algorithm",
    link: "Link to Project:",
    GithubProject: "https://github.com/AinazEstiri/TaskScheduler"
},
{
    name: "Reddit Crypto Crawler",
    desc: "Crawler for Reddit searching for keywords related to crypto on information to help with trading/buying/selling",
    image: Proj5,
    skills: "Python, HTML, Shell, Json",
    link: "Link to Project:",
    GithubProject: "https://github.com/AinazEstiri/Reddit-CryptoCrawler/tree/main"
},
{
    name: "Amazon Store DataBase",
    desc: "Implementation of Amazon Stores (with many functions and features) using the terminal as user interface",
    image: Proj6,
    skills: "Java, SQL, Shell",
    link: "Link to Project:",
    GithubProject: "https://github.com/AinazEstiri/AmazonStoreDB/tree/main"
}


]